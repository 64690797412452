import { Injectable } from '@angular/core';
import {ApiClient} from "../../shared/api/api";
import {BehaviorSubject, Observable} from "rxjs";
import {User, UserRequest} from "../models/users.model";
import {FilterApiModel} from "../models/FilterApi.model";

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  private endpoint = 'api/enterprise/employee';
  private endpointAdmin = 'api/admin/employee';
  private path: string = 'signature'
  private path2: string = 'toggle-state'
  private path3: string = 'approve-disapprove'


  private employeesSource = new BehaviorSubject<any[]>([]);
  employees$ = this.employeesSource.asObservable();

  constructor(private apiClient: ApiClient) {}


  getAllEmployees(): Observable<User[]> {
    return this.apiClient.getAll<User[]>(this.endpoint);
  }
  getAllEmployeesBo(): Observable<User[]> {
    return this.apiClient.getAll<User[]>(this.endpointAdmin);
  }
  paginatedEmployees(filter: FilterApiModel): Observable<User[]> {
    return this.apiClient.getAll<User[]>(this.endpoint +"?"+ filter.getRsqlQuery());
  }

  paginatedEmployeesWithoutCompanyFiltre(filter: FilterApiModel): Observable<User[]> {
    return this.apiClient.getAll<User[]>(this.endpointAdmin +"?"+ filter.getRsqlQuery());
  }

  getEmployeeById(id: string | null): Observable<User> {
    return this.apiClient.get<User>(this.endpoint, id);
  }

  getEmployeeByIdAdmin(id: string | null): Observable<User> {
    return this.apiClient.get<User>(this.endpointAdmin, id);
  }

  createEmployees(user: UserRequest): Observable<UserRequest> {
    return this.apiClient.post<UserRequest>(this.endpoint, user);
  }

  updateEmployees(id: string, user: UserRequest): Observable<UserRequest> {
    return this.apiClient.put<UserRequest>(this.endpoint, id, user);
  }

  validEmployee(id: string | null):Observable<any> {
    return this.apiClient.valid<any>(this.endpoint, id, this.path);
  }
  bulkCreateEmployees(payload:any):Observable<any> {
    return this.apiClient.bulk<UserRequest[]>(this.endpoint+"/bulk", payload);
  }

  statusPayment(id: string | null):Observable<any> {
    return this.apiClient.valid<any>(this.endpointAdmin, id, this.path2);
  }

  approveEmployee(id: string | null, approval: string | null):Observable<any> {
    return this.apiClient.valid<any>(this.endpointAdmin, id, this.path3 + "/" + approval);
  }

  deleteEmployees(id: string): Observable<void> {
    return this.apiClient.delete<void>(this.endpoint, id);
  }
}

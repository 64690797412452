export class FilterApiModel {
  private _query: string = "";
  private _page: number = 0;
  private _size: number = 10;
  private _sorted?: string; // Paramètre facultatif
  private _sortedDirection?: string; // Paramètre facultatif
  private andSeparator = ";";
  private orSeparator = ",";

  get page(): number {
    return this._page;
  }

  get size(): number {
    return this._size;
  }

  get sorted(): string | undefined {
    return this._sorted;
  }

  get sortedDirection(): string | undefined {
    return this._sortedDirection;
  }

  set query(value: string) {
    this._query = value;
  }

  set page(value: number) {
    this._page = value;
  }

  set size(value: number) {
    this._size = value;
  }

  set sorted(value: string | undefined) {
    this._sorted = value;
  }

  set sortedDirection(value: string | undefined) {
    this._sortedDirection = value;
  }

  get query(): string {
    return this._query;
  }

  constructor(page: number, size: number, query: string, sorted?: string, sortedDirection?: string) {
    this._page = page;
    this._size = size;
    this._query = query;
    this._sorted = sorted;
    this._sortedDirection = sortedDirection;
  }

  public filterAnd(key: string, valueFilter: any, ignoreCase: boolean = false, operator = "==") {
    return this.addFilter(key, valueFilter, ignoreCase, operator, this.andSeparator);
  }

  public filterOr(key: string, valueFilter: any, ignoreCase: boolean = false, operator = "==") {
    return this.addFilter(key, valueFilter, ignoreCase, operator, this.orSeparator);
  }

  public getRsqlQuery(): string {
    let queryParams = [];

    if (this._query.trim() !== "") {
      queryParams.push(`query=${this._query.trim()}`);
    }

    if (this._page != null) {
      queryParams.push(`page=${this._page}`);
    }

    if (this._size !== null) {
      queryParams.push(`size=${this._size}`);
    }

    if (this._sorted) {
      queryParams.push(`sorted=${this._sorted}`);
    }

    if (this._sortedDirection) {
      queryParams.push(`sortedDirection=${this._sortedDirection}`);
    }

    return queryParams.join("&");
  }

  private addFilter(
    key: string,
    valueFilter: object,
    ignoreCase: boolean,
    operator: string,
    separatorOperator: string
  ): void {
    if (!this._query) {
      if (!!valueFilter) {
        this._query = ignoreCase
          ? `${key}${operator}'%5E${valueFilter}'`
          : `${key}${operator}'${valueFilter}'`;
      }
    } else {
      if (!!valueFilter) {
        this._query += separatorOperator + (ignoreCase
          ? `${key}${operator}'%5E${valueFilter}'`
          : `${key}${operator}'${valueFilter}'`);
      }
    }
  }
}
